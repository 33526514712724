import { BASE_URL } from "../lib/constants";

export default function Footer() {
  return (
    <footer class="fixed inset-x-0 bottom-0 p-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2022{" "}
        <a href={BASE_URL} class="hover:underline">
          JMTT
        </a>
        . All Rights Reserved.
      </span>
      <ul class="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
          <a href="#" class="mr-4 hover:underline md:mr-6 ">
            地址: xxxxxxxxxxxx
          </a>
        </li>
        <li>
          <a href="#" class="mr-4 hover:underline md:mr-6">
            電話: xxxxxxxxxxx
          </a>
        </li>
      </ul>
    </footer>
  );
}
