import Head from "next/head";
import styles from "./layout.module.css";
import Footer from "./footer";

export default function Layout({ children }) {
  return (
    <div class="flex mb-4">
      <div class="flex-1 bg-gray-500 h-12">
        <Head>
          <title>杰碼科技</title>
        </Head>
        <main className={styles.main}>{children}</main>
        <Footer />
      </div>
    </div>
  );
}
