import Link from "next/link";
import styles from "./navbar.module.css";

export default function Navbar() {
  return (
    <nav class="relative w-full flex flex-wrap items-center justify-between py-4 text-gray-500 hover:text-gray-700 
    focus:text-gray-700 shadow-lg navbar navbar-expand-lg navbar-light px-6">
      <div class="container-fluid w-full flex flex-wrap items-center justify-between px-6">
        <div class="collapse navbar-collapse flex-grow items-center" id="navbarSupportedContent">
          <ul class="navbar-nav flex flex-row pl-6 list-style-none mr-auto">
            <Link href="/">
              <a className={styles.a}>產品項目</a>
            </Link>
            <Link href="/about">
              <a className={styles.a}>
                關於我們
              </a>
            </Link>
            <Link href="/contact">
              <a className={styles.a}>
                聯絡我們
              </a>
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
}
