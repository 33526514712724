import Layout from "../components/layout";
import Navbar from "../components/navbar";

export default function Index() {
  return (
    <section>
      <h2>產品項目</h2>
      <p>
        1
      </p>
      <p>
        2
      </p>
      <p>
        3
      </p>
      <h3>Try It Out</h3>
      <p>
        4
      </p>
    </section>
  );
}

Index.getLayout = function getLayout(page) {
  return (
    <Layout>
      <Navbar />
      {page}
    </Layout>
  );
};
